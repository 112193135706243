import * as Sentry from '@sentry/nextjs'
import confetti from 'canvas-confetti'
import Image from 'next/image'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import { Steps } from 'rsuite'

import ButtonLoader from '@components/core/buttons/loader'
import { SubmitButton } from '@components/core/modal/style'
import { PlusImage } from '@components/settings/style'
import { useEnvironment } from '@context/environment'
import { useUser } from '@context/user'
import { ModalClosed, ModalOpened } from '@lib/analytics'
import api from '@lib/api'

import CreatedClient from '../../modals/createdClient'
import {
  ActionButton,
  OnboardingActionButtonsContainer,
  OnboardingContent,
  OnboardingDescription,
  OnboardingTitle,
  Panel,
  StepItem,
  TextButton,
} from './style'

export enum CustodianOnboardingStatus {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  CLIENT_CREATED = 'CLIENT_CREATED',
  WALLET_CREATED = 'WALLET_CREATED',
  SIGN_PERFORMED = 'SIGN_PERFORMED',
  COMPLETED = 'COMPLETED',
}

const onboardingSteps: Record<CustodianOnboardingStatus, number> = {
  [CustodianOnboardingStatus.ACCOUNT_CREATED]: 1,
  [CustodianOnboardingStatus.CLIENT_CREATED]: 2,
  [CustodianOnboardingStatus.WALLET_CREATED]: 3,
  [CustodianOnboardingStatus.SIGN_PERFORMED]: 4,
  [CustodianOnboardingStatus.COMPLETED]: 5,
}

const ClientCreatedStepDescription: React.FC = () => {
  const { environment } = useEnvironment()

  const [createdClient, setCreatedClient] = useState<CreatedClient | null>(null)
  const [creatingClient, setCreatingClient] = useState<boolean>(false)

  const onClientCreate = async () => {
    if (creatingClient) return
    setCreatingClient(true)

    try {
      // Attempt to create a new client.
      const client = await api.custodian.environments.clients.create(
        environment.id,
      )

      // Track the event.
      analytics.track(ModalOpened, {
        environmentId: environment.id,
        environmentName: environment.name,
        title: 'Created Client',
      })

      // Show the modal.
      setCreatedClient(client)
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    } finally {
      setCreatingClient(false)
    }
  }

  return (
    <>
      {createdClient && (
        <CreatedClient
          client={createdClient}
          modalZIndex={5}
          closeIconZIndex={6}
          close={() => {
            // Close the modal.
            setCreatedClient(null)

            // Track the event.
            analytics.track(ModalClosed, {
              environmentId: environment.id,
              environmentName: environment.name,
              title: 'Created Client',
            })
          }}
        />
      )}
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div>
          The first step is to create a new test Client and Client API Key.
          Every Portal Wallet is associated with a Client and is authenticated
          with a Client API Key. You can create a test Client and Client API Key
          in the&nbsp;
          <Link href="/settings#client-api-keys" target="_blank">
            Settings page
          </Link>
          &nbsp;or by clicking this button.
        </div>
        <ActionButton onClick={onClientCreate}>
          {creatingClient ? (
            <ButtonLoader />
          ) : (
            <>
              New{' '}
              <PlusImage>
                <Image
                  alt="Plus"
                  height={14}
                  src="/icons/icon-plus.svg"
                  width={14}
                />
              </PlusImage>
            </>
          )}
        </ActionButton>
      </div>
    </>
  )
}

const triggerConfetti = () => {
  const count = 400
  const defaults = {
    origin: { y: 0.7 },
  }

  function fire(particleRatio: number, opts: Record<string, number>) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    })
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  })
  fire(0.2, {
    spread: 60,
  })
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  })
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  })
}

const Onboarding = (): JSX.Element => {
  const { user, setUser } = useUser()

  const [onboardingSyncIntervalId, setOnboardingSyncIntervalId] =
    useState<NodeJS.Timeout>()
  const [syncingOnboardingStatus, setSyncingOnboardingStatus] =
    useState<boolean>(false)

  const onboardingStatus = user?.custodian?.onboardingStatus
  const doesNotNeedOnboarding = [
    CustodianOnboardingStatus.COMPLETED,
    CustodianOnboardingStatus.SIGN_PERFORMED,
  ].includes(onboardingStatus)

  const fetchClientsCreated = useCallback(async () => {
    const clientInsights = await Promise.all(
      user.custodian.environments.map(async (environment) => {
        return api.custodian.environments.insights.getClientsCreated(
          environment.id,
          {
            cumulative: true,
            endsAtMS: Date.now(),
            granularity: 'year',
            startsAtMS: new Date('2015-01-01').valueOf(),
          },
        )
      }),
    )

    return clientInsights.reduce((acc, insight) => acc + insight.total, 0)
  }, [user])

  const fetchWalletsCreated = useCallback(async () => {
    const walletInsights = await Promise.all(
      user.custodian.environments.map(async (environment) => {
        return api.custodian.environments.insights.getTotalWallets(
          environment.id,
          {
            cumulative: true,
            endsAtMS: Date.now(),
            granularity: 'year',
            startsAtMS: new Date('2015-01-01').valueOf(),
          },
        )
      }),
    )

    return walletInsights.reduce((acc, insight) => acc + insight.total, 0)
  }, [user])

  const fetchSignMpcOperations = useCallback(async () => {
    const signMpcOps = await Promise.all(
      user.custodian.environments.map(async (environment) => {
        return api.custodian.environments.mpcOperations.count(environment.id, {
          simpleType: 'SIGN',
        })
      }),
    )

    return signMpcOps.reduce((acc, data) => acc + data.count, 0)
  }, [user])

  const syncOnboardingStatus = useCallback(async () => {
    if (syncingOnboardingStatus) return
    if (!user?.custodian?.environments?.length) return

    setSyncingOnboardingStatus(true)

    try {
      const [clientsCreated, walletsCreated, signMpcOps] = await Promise.all([
        fetchClientsCreated(),
        fetchWalletsCreated(),
        fetchSignMpcOperations(),
      ])

      let newOnboardingStatus = CustodianOnboardingStatus.ACCOUNT_CREATED
      if (signMpcOps > 0) {
        newOnboardingStatus = CustodianOnboardingStatus.SIGN_PERFORMED
      } else if (walletsCreated > 0) {
        newOnboardingStatus = CustodianOnboardingStatus.WALLET_CREATED
      } else if (clientsCreated > 0) {
        newOnboardingStatus = CustodianOnboardingStatus.CLIENT_CREATED
      }

      const custodian = await api.custodian.update({
        onboardingStatus: newOnboardingStatus,
      })

      setUser({
        ...user,
        custodian: {
          ...user.custodian,
          ...custodian,
        },
      })
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    } finally {
      setSyncingOnboardingStatus(false)
    }
  }, [
    user,
    syncingOnboardingStatus,
    fetchClientsCreated,
    fetchWalletsCreated,
    fetchSignMpcOperations,
  ])

  const setOnboardingCompleted = useCallback(async () => {
    try {
      triggerConfetti()

      const custodian = await api.custodian.update({
        onboardingStatus: CustodianOnboardingStatus.COMPLETED,
      })

      setUser({
        ...user,
        custodian: {
          ...user.custodian,
          ...custodian,
        },
      })
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }, [user])

  const markOnboardingSkipped = useCallback(async () => {
    try {
      clearInterval(onboardingSyncIntervalId)

      await api.custodian.update({
        onboardingSkipped: 'true',
      })

      setUser({
        ...user,
        custodian: {
          ...user.custodian,
          onboardingSkipped: true,
        },
      })
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }, [user])

  useEffect(() => {
    if (!user) {
      clearInterval(onboardingSyncIntervalId)
      return
    }

    if (doesNotNeedOnboarding || user.custodian.onboardingSkipped) {
      clearInterval(onboardingSyncIntervalId)
      return
    }

    const timer = setInterval(() => {
      syncOnboardingStatus()
    }, 2500)

    setOnboardingSyncIntervalId(timer)

    return () => {
      clearInterval(timer)
    }
  }, [user])

  return (
    <OnboardingContent
      hide={doesNotNeedOnboarding || user?.custodian?.onboardingSkipped}
    >
      <OnboardingTitle>Welcome to Portal!</OnboardingTitle>
      <OnboardingDescription>
        Let&apos;s get started by spinning up your first Portal Wallet and
        submitting a transaction!
        <br />
        <br />
        Start by opening up our Getting Started Guide for either one of
        our&nbsp;
        <Link
          href="https://docs.portalhq.io/getting-started/sdk-quick-starts"
          target="_blank"
        >
          SDKs
        </Link>
        &nbsp;or our&nbsp;
        <Link
          href="https://docs.portalhq.io/getting-started/enclave-mpc-api-quick-start"
          target="_blank"
        >
          Enclave API
        </Link>
        . (We recommend starting with the API for your first wallet even if you
        plan to use an SDK later.) We&apos;ll track your progress here as you
        work through the guide.
      </OnboardingDescription>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '24px 0',
        }}
      >
        <Steps
          style={{ width: '100%' }}
          vertical
          current={onboardingSteps[onboardingStatus] || 1}
        >
          <StepItem
            title={
              <Panel
                header="Sign in to the Portal Dashboard"
                collapsible
                bordered
              >
                You&apos;ve already done this! :) The Admin Dashboard is where
                you can manage API Keys, view your wallets, and manage security
                settings.
              </Panel>
            }
          />
          <StepItem
            title={
              <Panel
                header="Generate a Client API Key for testing"
                defaultExpanded={onboardingSteps[onboardingStatus] === 1}
                collapsible
                bordered
              >
                <ClientCreatedStepDescription />
              </Panel>
            }
          />
          <StepItem
            title={
              <Panel
                header="Create a Portal Wallet"
                defaultExpanded={onboardingSteps[onboardingStatus] === 2}
                collapsible
                bordered
              >
                The next step is create a Portal Wallet using the Client API
                Key. Follow&nbsp;
                <Link
                  href="https://docs.portalhq.io/getting-started/enclave-mpc-api-quick-start#creating-a-wallet"
                  target="_blank"
                >
                  these steps in the Getting Started Guide
                </Link>
                &nbsp;to create your first Portal Wallet.
              </Panel>
            }
          />
          <StepItem
            title={
              <Panel
                header="Submit a transaction"
                defaultExpanded={onboardingSteps[onboardingStatus] === 3}
                collapsible
                bordered
              >
                You&apos;ve created a Portal Wallet! The final step is to&nbsp;
                <Link href="/faucet" target="_blank">
                  fund your wallet with some testnet funds
                </Link>
                &nbsp;and submit your first transaction. Follow&nbsp;
                <Link
                  href="https://docs.portalhq.io/getting-started/enclave-mpc-api-quick-start#submitting-a-transaction"
                  target="_blank"
                >
                  the steps in the Getting Started guide
                </Link>
                &nbsp;to submit your first transaction!
              </Panel>
            }
          />
        </Steps>
        {onboardingStatus === CustodianOnboardingStatus.SIGN_PERFORMED && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '36px',
            }}
          >
            <div>
              You&apos;ve successfully submitted a transaction and completed the
              Getting Started Guide! Check out the rest of our&nbsp;
              <Link href="https://docs.portalhq.io/" target="_blank">
                developer documentation
              </Link>
              &nbsp;as you continue your integration.
            </div>
          </div>
        )}
      </div>
      <OnboardingActionButtonsContainer>
        <TextButton onClick={markOnboardingSkipped}>
          Do not show again
        </TextButton>
        <SubmitButton
          disabled={
            onboardingStatus !== CustodianOnboardingStatus.SIGN_PERFORMED
          }
          id="modal-onboarding-finish"
          onClick={setOnboardingCompleted}
        >
          Finish
        </SubmitButton>
      </OnboardingActionButtonsContainer>
    </OnboardingContent>
  )
}

export default Onboarding
