import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Panel as RSuitePanel, Steps } from 'rsuite'

import { Button } from '@components/core/buttons'

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const Checkbox = styled.input`
  margin-right: 10px;
  cursor: pointer;
`

export const CheckboxLabel = styled.label`
  cursor: pointer;
  user-select: none;
`

export const ActionButton = styled(Button)(
  {
    border: 'none',
    fontSize: '0.9rem',
    padding: '12px 18px',
    marginLeft: '16px',
  },
  ({ theme }) => ({
    background: theme.colors.backgrounds.primaryLight,
    borderRadius: theme.borderRadius.default,
    color: theme.colors.primary,

    '&:hover': {
      background: theme.colors.backgrounds.primary,
    },

    '&:focus': {
      outline: `2px solid ${theme.colors.primary}`,
    },
  }),
)

export const OnboardingTitle = styled.span({
  fontSize: '1.25rem',
  fontWeight: 600,
  margin: '0 0 24px',
  textAlign: 'center',

  '.font-weight-normal': {
    fontWeight: 400,
  },
})

export const OnboardingDescription = styled.div(
  {
    fontSize: '0.925rem',
    lineHeight: '1.5',
    margin: '0 0 20px 0',
    padding: '0 32px',
    textWrap: 'pretty',
    textAlign: 'left',
  },
  ({ theme }) => ({
    color: theme.colors.text.body,
  }),
)

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const OnboardingContent = styled.div<{ hide: boolean }>(
  ({ hide }) => ({
    animation: hide ? `${fadeOut} 0.6s ease-out` : `${fadeIn} 0.6s ease-in`,
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 12px',
    margin: '0 0 24px',
  }),
  ({ theme }) => ({
    border: `1px solid ${theme.colors.border.default}`,
    borderRadius: theme.borderRadius.default,
  }),
)

export const OnboardingActionButtonsContainer = styled.div({
  padding: '0 12px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

export const StepItem = styled(Steps.Item)({}, ({ theme }) => ({
  '.rs-steps-item-title': {
    color: theme.colors.text.body,
    fontWeight: 600,
    width: '100%',
  },
  '--rs-steps-state-process': theme.colors.blue,
  '--rs-steps-state-finish': theme.colors.blue,
  '--rs-btn-subtle-hover-bg': theme.colors.backgrounds.primary,
}))

export const TextButton = styled.span(
  {
    cursor: 'pointer',
  },
  ({ theme }) => ({
    color: theme.colors.primary,
  }),
)

export const Panel = styled(RSuitePanel)({}, ({ theme }) => ({
  borderRadius: theme.borderRadius.default,

  '.rs-panel-title': {
    color: theme.colors.text.body,
    fontWeight: 600,
    width: '100%',
  },
  '.rs-panel-body': {
    color: theme.colors.text.body,
    fontWeight: 'normal',
    fontSize: '0.925rem',
  },
}))
