import Code from '@components/core/code'
import CopyableCode from '@components/core/copyableCode'
import Modal from '@components/core/modal'
import {
  CancelButton,
  Content,
  Description,
  Footer,
  StyledPageBreak,
  Title,
} from '@components/core/modal/style'
import Secret from '@components/core/secret'

type CreatedClientProps = {
  client: CreatedClient
  close: () => void
  closeIconZIndex?: number
  modalZIndex?: number
}

const CreatedClient = ({ client, close, closeIconZIndex, modalZIndex }: CreatedClientProps): JSX.Element => {
  const onClose = () => {
    // Close modal.
    close()
  }

  return (
    <Modal closeIconZIndex={closeIconZIndex} modalZIndex={modalZIndex} onClose={onClose}>
      <Content>
        <Title id="modal-created-client-title">Test Client API Key</Title>
        <Description>
          <p>
            Below is a generated test client API key, which you can use to
            quickly get started with Portal.
          </p>
          <p>
            Learn how to use it{' '}
            <a
              href="https://docs.portalhq.io/getting-started/enclave-mpc-api-quick-start#creating-a-wallet"
              rel="noreferrer noopener"
              target="_blank"
            >
              in our documentation
            </a>
            .
          </p>
          <br />
          <p id="modal-created-client-value">
            <strong>Client API Key</strong>:{' '}
            <CopyableCode value={client.clientApiKey} />
          </p>
          <p>
            <strong>Client ID</strong>:
            <br />
            <Code>{client.id}</Code>
          </p>
        </Description>
        <StyledPageBreak className="hr-break" />
        <Footer>
          <CancelButton
            className="cancel-button"
            id="modal-created-client-close"
            onClick={onClose}
            type="button"
          >
            Close
          </CancelButton>
        </Footer>
      </Content>
    </Modal>
  )
}

export default CreatedClient
