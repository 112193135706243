import styled from '@emotion/styled'

export const Code = styled.code(
  {
    borderRadius: '3px',
    display: 'inline-block',
    fontFamily: 'monospace',
    fontSize: '0.9em',
    lineHeight: '1.4',
    padding: '1px 3px',
  },
  ({ theme }) => ({
    background: theme.colors.backgrounds.greyLight,
    color: theme.colors.text.button,
  }),
)

export default Code
