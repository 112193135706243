import PageBreak from '@components/core/pageBreak'
import PageContent from '@components/core/pageContent'
import { useUser } from '@context/user'

import ClientsCreated from './clientsCreated'
import Onboarding, { CustodianOnboardingStatus } from './onboarding'
import { MyDashboardHeading } from './style'
import TotalMpcOperations from './totalMpcOperations'
import TotalWallets from './totalWallets'

const MyDashboardComponent = (): JSX.Element => {
  const { user } = useUser()

  const showOnboarding =
    !!user &&
    !user.custodian?.onboardingSkipped &&
    user.custodian?.onboardingStatus !== CustodianOnboardingStatus.COMPLETED

  return (
    <PageContent>
      <MyDashboardHeading id="page-home-title">Home</MyDashboardHeading>
      {showOnboarding && (
        <>
          <Onboarding />
        </>
      )}
      <ClientsCreated />
      <TotalWallets />
      <TotalMpcOperations />
    </PageContent>
  )
}

export default MyDashboardComponent
